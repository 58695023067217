*,:after,:before{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;padding:0;margin:0;outline:0}
html {margin-top: 0px !important;}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}
body{font-family: "Josefin Sans", sans-serif;color:#000;font-size:16px;line-height:1.5;-webkit-text-size-adjust:none}
img{max-width:100%;height:inherit}
a{text-decoration:none;color:#000}
ol,ul{list-style-type:none}
h2 {font-size: 50px;margin-bottom: 30px;}
section {padding: 10px 0px;}
.main_header {width: 100%;height: auto;z-index: 999;position: fixed;top: 0;}
.main-header {background-color: #2B3215;width: 100%;box-shadow: 0 4px 4px #c7cfcf;}
.container {max-width: 1250px;margin: auto;}
.top-header,.bottom-header {width: 100%;display: flex;padding: 10px 5px;justify-content: space-between;}
.location {
	display: flex;
    align-items: center;
}
.location img {
	width: 30px;
    height: 30px;
    margin-right: 10px;
}
.location span,.call-now span,.call-now span a {
	font-size: 16px;
    font-weight: 600;
    color: #1a2035;
}
.logo-cont {
	width: 125px;
}
.main-menu {
	display: flex;
    align-items: center;
}
.main-menu li {
	margin: 0px 20px;
}
.main-menu li:last-child {margin-right: 0px;}
.main-menu li a {
	font-size: 18px;
    font-weight: 600;
    color: #1a2035;
}
.container-fluid {margin-top: 95px;}
.d-flex {
	display: flex;
    width: 100%;
}
.left-cont {width: 40%;position: relative;display: flex;}
.left-cont img {width: 100%;}
.subtitle {
    display: inline-block;
    text-transform: uppercase;
    color: #1a2035;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 12px;
    position: relative;
}
.subtitle:after {
    position: absolute;
    top: 50%;
    right: -70px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 50px;
    height: 1px;
    display: block;
    content: "";
    background-color: #1a2035;
}
.right-cont {
	width: 60%;
    padding: 0px 40px;
}
.aboutus p {
    line-height: 1.5;
    margin-bottom: 30px;
}
h3 {
    font-size: 20px;
    margin-bottom: 20px;
}
.top-heading {
	text-align: center;
    max-width: 80%;
    margin: 0px auto;
    margin-bottom: 30px;
}
.choose-cont {
	width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.choose-cont .card {
	width: 25%;
    display: flex;
}
.choose-cont figure {
	width: 100%;
	padding: 40px;
}
.choose-cont figure .img-wrap {
	display: flex;
    width: 100%;
    align-items: center;
    text-align: center;
    margin-bottom: 10px;
}
.choose-cont figure .img-wrap img {
	margin: 0px auto;
    width: 60px;
    height: 60px;
}
.choose-cont figure figcaption {
	text-align: center;
}
.choose-cont .card:hover {
	box-shadow: 0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%), 0 8px 10px -7px rgb(0 0 0 / 20%);
}
.ourmain-menu {
	width: 100%;
    justify-content: center;
}
.ourmain-menu li a:hover {
	color: #ffc107;
}
.equal-cont {width: 50%;}
.contact {
	background-color: #f8f8f8;
}
.contact form {max-width: 60%;margin: 0px auto;}
.contact form input {
	padding: 20px;
    border: none;
    width: 100%;
    margin-bottom: 20px;
}
.contact form button {
	background-color: #ffc107;
    padding: 15px 30px;
    border: none;
    color: #212121;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    font-family: "Josefin Sans", sans-serif;
}
.modal-content {max-height: 500px !important;
         
}
.modal-content form {width: 100%;}
.modal-content form input {
	padding: 15px;
    border: none;
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid #000;
    border-radius: 10px;
    font-family: "Josefin Sans", sans-serif;
}
.modal-content form button {
	background-color: #2b3215;
    padding: 15px 30px;
    border: none;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    font-family: Lora;
}

.modal-content form .skipbtn {
	background-color: lightgrey;
    padding: 15px 30px;
    border: none;
    color: #212121;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    font-family: Lora;
} {
	background-color: #ffc107;
    padding: 15px 30px;
    border: none;
    color: #212121;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
}
.single-add {
	margin-bottom: 20px;
}
.single-add h3 {
	margin-bottom: 10px;
}
.modal {
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 1;
    background: rgba(0, 0, 0, .7);
    z-index: 1050;
    display: block;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;
}
.modal-dialog {
    transform: translate(0, 0);
    transition: transform .3s ease-out, -webkit-transform .3s ease-out, -o-transform .3s ease-out;
    width: 500px;
    height: fit-content;
    margin: auto;
}
.modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
        padding: 30px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 15px;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    outline: 0;
    min-height: 200px;
    max-height: 400px;
    overflow-y: auto;
   
}
.close {
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0;
    right: 0;
    color: #868686;
    float: right;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    text-shadow: 0 1px 0 #868686;
    display: inline-flex;
    margin: auto;
    opacity: .5;
}
.close img {
    width: 20px;
    height: 20px;
    margin: auto;
    background-color: #000;
    border-radius: 50%;
    padding: 5px;
}
.modal, .modal-dialog {
    position: fixed;
    bottom: 0;
}
.modal, .modal-dialog {
    left: 0;
    top: 0;
    right: 0;
}
.toogle-bar {display: none;}
.sticky .container-fluid {margin-top: 100px;}
.d-flex-cards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap:10px;
}
.d-flex-cards .card {
    width: 32%;
    background-color: #f6f6f6;
    /* margin-right: 4%;
    margin-bottom: 4%; */
}
.d-flex-cards .card figure {
    width: 100%;
    display: flex;
    background-color: #f6f6f6;
    border-radius: 5px;
    margin-bottom: 0px;
}
.d-flex-cards .card:nth-child(3n) {
    margin-right: 0px;
}
.strip {
    width: 100%;
    display: inline-flex;
    margin-bottom: 10px;
    cursor: pointer;
}
.strip span {
    font-size: 26px;
    font-weight: 600;
    font-family: "Bodoni FLF";
    text-transform: uppercase;
    color: #a27455;

}
.strip:after {
    border-bottom-style: solid;
    border-bottom-width: 3px;
    border-right-style: solid;
    border-right-width: 3px;
    content: "";
    display: inline-block;
    height: 12px;
    width: 12px;
    border-color: #000;
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    transform: rotate(45deg);
    margin: auto;
    margin-right: 30px;
}
.d-flex-cards .card figure .img-wrap {
    width: 180px;
    display: flex;
}
.d-flex-cards .card figure .img-wrap img {
    margin: auto 0px;
    width: 100%;
    aspect-ratio: 10 / 9;
    object-fit: cover;
    object-position: center;}
.d-flex-cards .card figure figcaption {
    padding: 5px;
    width: calc(100% - 180px);
    object-fit: cover;
}
.d-flex-cards .card figure figcaption h3 {
    font-size: 16px;
    font-weight: 600;
    color: #393939;
    margin-bottom: 5px;
    font-family: "Lora";
}
.d-flex-cards .card figure figcaption p {
    font-size: 13px;
    font-weight: 500;
    color: #000;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    height: 65px;
    overflow: hidden;
    width: 100%;
    margin-bottom: 5px;
}
.patch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}
.d-flex-cards .card figure figcaption .patch .must{
    padding: 5px 10px;
    font-size: 13px;
    background-color: #f06c21;
    color: #fff;
}
.d-flex-cards .card figure figcaption .patch .new {
    padding: 5px 10px;
    font-size: 13px;
    background-color: #e20c0c;
    color: #fff;
}
.patch img {
    width: 20px;
    height: 20px;
}
.d-flex-cards .card figure figcaption .cost {
    font-size: 20px;
    font-weight: 600;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    color: #008001;
    margin-left: 10px;
}
.hidden {
  display: none;
}
.submit-skip{display: inline-flex;width: 100%;justify-content: space-around;}

.patch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}
.patch p{
  font-family: Lora;
}
.d-flex-cards .card figure figcaption .patch .must {
    padding: 5px 10px;
    font-size: 13px;
    background-color: #e5d4c2;
    color: black;
    font-family: Lora;
    font-style: italic;
}
.d-flex-cards .card figure figcaption .patch .New {
    
    padding: 5px 10px;
    font-size: 10px;
    background-color: #e20c0c;
    color: #fff;
    font-family: Lora;
}
.patch img {
    width: 15px;
    height: 15px;
}
.d-flex-cards .card figure figcaption .cost {
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    color: #008001;
}
.l-arrow-stick {
    position: relative;
    display: inline-block;
    cursor: pointer;
    width: 30px;
    height: 30px;
    box-sizing: border-box;
}
.l-arrow-stick::before {
    content: "";
    position: absolute;
    left: 40%;
    top: 50%;
    width: 30%;
    height: 30%;
    border-left: 3px solid #fff;
    border-bottom: 3px solid #fff;
    -webkit-transform: translate(-60% -50%) rotate(45deg);
    transform: translate(-60%, -50%) rotate(45deg);
}
.l-arrow-stick::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 60%;
    height: 2px;
    background: #fff;
    -webkit-transform: translate(-40%, -50%);
    transform: translate(-40%, -50%);
}
.black-patch {
    padding: 10px;
    background-color: #000;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    position: fixed;
    width: 100%;
    top: 0;
}
.back-button {
    display: flex;
    align-items: center;

}
.singlepage-content {
    display: flex;
    width: 100% ;
}
.article-img {width: 48%;margin-right: 2%;display: inline-flex;}
.main-description {width: 50%;padding: 10px;box-shadow: 0 4px 4px #c7cfcf;}
.article-img img {width: 100%;}
.name-desk {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
}

.name-desk .cost{
    display: flex;
    flex-wrap: nowrap;
    font-weight: bold;
    font-size: 16px;
}
.item-name {
    display: inline-flex;
    align-items: center;
}
.item-name img {
    width: 15px;
    height: 15px;
    margin-right: 15px;
}
.item-name h3 {
    margin-bottom: 0px;
    font-size: 18px;
}
.description {
    /* border-bottom: 2px solid #ededed; */
    padding: 10px 0px;
}
.description p {
    color: #7f7f7f;
    font-size: 16px;
}
.itemtags {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0px;
    border-bottom: 2px solid #ededed;
    margin-bottom: 10px;
}
.itemtags li {
    font-size: 16px;
    color: #000;
    padding: 0px 10px;
    border-right: 2px solid #000;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 25px;
    margin-bottom: 10px;
}
.itemtags li:last-child {border-right: none;}
.addon-item {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}
.addon-item span {
    color: #7f7f7f;
    font-size: 14px;
    font-weight: 600;
}
.single-fluid {margin-top: 70px;}
.main-page-cont {
    background-image: url("./banner.jpg");
    background-position: center;
    background-color: #905a5a;
    background-repeat: no-repeat;
    background-size: cover;
    color: #ffffff;
    padding: 50px;
    min-height: 1000px;
}
.main-page-cont .logo,.social-icons {
    max-width: 60%;
    width: fit-content;
    margin: 0px auto;
    margin-bottom: 20px;
}
.main-page-cont .logo img {
    width: 110px;
    /* filter: invert(1); */
    border-radius: 54px;
}
.social-icons ul {
    display: flex;
}
.social-icons ul li {
    width: 30px;
    height: 30px;
    margin-right: 20px;
}
.social-icons ul li:last-child {
    width: 30px;
    height: 30px;
    margin-right: 0px;
}
.menu-btns {
    max-width: 30%;
    margin: 0px auto;
}
.menu-btns ul li {margin-bottom: 20px;width: 100%;}
.menu-btns ul li a {
    padding: 20px 20px;
    width: 100%;
    display: block;
    background-color: #fff;
    border: 2px solid #b48383;
    border-radius: 50px;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    font-family: "Playfair Display, serief";
} 
.main-page-cont p,.main-page-cont p a {
    font-size: 16px;
    color: #fff;
    text-align: center;
}
.menu-cont-page {
    background-color: #efe9e9;
    min-height: 600px;
}
.title-cont {
    display: block;
    text-align: center;
    padding: 30px;
}
.title-cont h1 {
    font-size: 28px;
}
.title-cont .sub-title {
    font-size: 16px;
    color: #222222;
}
.click-menu {
    max-width: 50%;
    margin: 0px auto;
}
.bg-menu {
    padding: 60px;
    background-size: 200% 200%;
    background-repeat: no-repeat;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    position: relative;
}
.bg-menu a {
    text-align: center;
    display: flex;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
}
.bg-menu a .item {
    margin: auto;
    font-size: 28px;
    color: #fff;
}
.container-fluid .active .d-flex-cards{display: none;}
.container-fluid .active .strip:after{
transform: rotate(225deg);
}
.container-fluid .active{padding: 10px 0px;}

/* filter */
.filter-cont {
    width: 100%;
    display: inline-flex;
    align-items: center;
    overflow-x: auto;
    margin-bottom: 12px;
}
.filter-cont::-webkit-scrollbar {display: none;}
.fiter-main {
    display: inline-flex;
    align-items: center;
    padding: 5px 15px;
    border: 1px solid #eee;
    flex: 0 0 auto;
    border-radius: 25px;
    margin-right: 10px;
}
.fiter-main input {margin-right: 10px;}
.fiter-main img {
    width: 15px;
    height: 15px;
    margin-right: 10px;
}
.fiter-main span {
    font-size: 13px;
    color: #414141;
}
.container-radio {
    display: inline-flex;
    position: relative;
    padding-left: 25px;
    align-items: center;
    cursor: pointer;
    font-size: 13px;
    line-height: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 0px;
}

/* Hide the browser's default radio button */
.container-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.container-radio .checkmark {
    position: absolute;
    top: 2.5px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #f5f5f5;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
/* .container-radio:hover input ~ .checkmark {
    background-color: #008001;
} */

/* When the radio button is checked, add a blue background */
.container-radio input:checked ~ .checkmark {
    background-color: #008001;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.container-radio .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-radio input:checked ~ .checkmark:after {
    display: block;
}

.container-radio input:checked~.checkmark {
    background-color: #008001;
}
/* Style the indicator (dot/circle) */
.container-radio .checkmark:after {
    top: 5px;
    left: 5px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: white;
}
.sticky-menu {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #dbb198;
    padding: 5px;
    box-shadow: 4px 0px 4px #c7cfcf;
}
.sticky-menu ul {
    display: inline-flex;
    overflow-x: auto;
    margin-bottom:0px;
    width: 100%;
}
.sticky-menu ul::-webkit-scrollbar {display: none;}
.sticky-menu ul li {
    padding: 5px 10px;
    /* background-color: #e7e7e7; */
    flex: 0 0 auto;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
}
.scroll{padding-top: 80px;}

.searchform{ 
    margin-bottom: 10px;
    position: relative;
    border:1px solid lightgrey;
    border-radius:12px;
}
.searchform input {
    width: 100%;
    padding-right: 30px;
    border: 1px solid lightgrey;
    
}
#search {
    position: absolute;
    right: 10px;
    top: 5px;
    padding:5px;
}
.searchform input:focus {
    border:1.5px solid #333;
}
.container-fluid .menuSection:last-child{
padding-bottom: 50px;
}
.productimage input{
    width: 100%;
    border: 1px solid lightgrey;
}

.article-social-share {
    width: 100%;
    float: left;
    margin-top: 10px;
    border-bottom: 2px solid #ededed;;
}

.article-social-share .post-share {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    z-index: 1;
    width: 40px;
    border-radius: 100px;
}

.article-social-share a {
    position: relative;
    display: inline-block;
    margin: 0 3px 7px;
    height: 40px;
    min-width: 40px;
    font-size: 11px;
    text-align: center;
    vertical-align: middle;
    color: #fff;
}

.feedback-fluid {margin-top: 65px; background: lightgrey;}
.feedback-form-cont {
    padding: 30px 0px;
    border-radius: 50px;
    max-width: 800px;
    margin: 0px auto;
}
.feedback-form-cont .flex-cont {
    display: flex;
    width: 100%;
    background-color: lightgrey;
    padding: 20px;
    border-radius: 50px;
}
.left-feedback-cont {width: 100%;}
.logo-img {
    margin: 0px auto;
    width: fit-content;
    margin-bottom: 20px;
}
.feedback-form .form-group {
    padding: 15px;
    background-color: #fff;
    border-radius: 10px;
}
.feedback-form .form-group label {
    color: #000;
    font-weight: 500;
    font-family: Georgia;
    font-size: 18px;
}
.feedback-form .form-group input[type=radio] {
    max-width: 30px;
    min-width: 30px;
    margin-right: 20px;
    max-height: 21px;
}
.feedback-form .form-group .d-flex {margin-bottom: 10px;}
.feedback-form .form-group .d-flex label {
    margin: auto 0px;
    font-family: Georgia;
    font-size: 14px;
    width: calc(100% - 50px);
}
.feedback-form .form-group h3 {
    font-size: 20px;
    font-weight: 500;
    font-family: Georgia;
    font-size: 18px;
    line-height: 30px;
}
.top-fields input {margin-bottom: 10px;font-family: Lora;}
.submit-feedback {
    background-color: #191F38;
    padding: 15px 30px;
    border: none;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    font-family: Lora;
    margin: 0px auto;
    display: flex;
    margin-bottom: 20px;

}
.thankyou-cont h4 {
    font-size: 18px;
    font-weight: 500;
    color: #000;
    font-family: Lora;
    text-align: center;
    margin-bottom: 20px;
    line-height: 30px;
} 
.thankyou-cont img {
    margin: 0px auto;
    display: flex;
}

.table-responsive1 {
    display: flex;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    flex-wrap: wrap;
}
.delivery-partners {display: flex;
    align-items: center;
    margin-right: 20px;
}
.delivery-partners ul{
        margin-bottom: 0px;
}
.delivery-partners ul li a{
color:#cca78b;
font-size: 16px;
font-family: "Lora";
}
@media (max-width:768px) {

.container {padding: 0px 10px;}
.toogle-bar {display: block;margin: auto 0px;}
#toggleNav, .menuNavigation {
    display: none;
}
body.openMenu .MenuBtn i:first-child {
    transform: translateY(2px) translateX(0) rotate(45deg);
    background: #fff;
}
body.openMenu .MenuBtn i:nth-child(2) {
    opacity: 0;
    background: #fff;
}
body.openMenu .MenuBtn i {
    padding-top: 0;
}
body.openMenu .MenuBtn i:nth-child(3) {
    transform: translateY(-2px) translateX(0) rotate(-45deg);
    background: #fff;
}
#toggleNav {
    display: inline-flex;
}
.MenuBtn i {
    width: 24px;
    display: block;
    border-top: 2px solid #e5d4c2;
    padding-top: 4px;
}
.modal-dialog {width: 100%;}
.main-menu {display: none;}
.menuNavigation {
    width: 100%;
    float: left;
    /* padding: 10px 0 100px 0; */
    position: absolute;
    top: 64px;
    left: 0;
    z-index: 8;
    height: 100vh;
    background: #fff;
    overflow: auto;
}
.menuNavigation .trending_link {
    border-bottom: 6px solid #363636;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.menuNavigation .trending_link a {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 10px;
    width: 100%;
    font-size: 16px;
    color: #0c0d11;
}
.menuNavigation ul.listItems {
    list-style: none;
    padding: 10px;
    margin: 0;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.menuNavigation ul.listItems li {
    border-bottom: 1px dashed #0b0b0d;
    margin: 0;
    display: block;
}
.menuNavigation ul.listItems>li {
    width: 100%;
    text-align: left;
    line-height: 30px;
    padding: 5px 0;
    position: relative;
    margin: 0;
}
.menuNavigation ul.listItems li a.menuHead {
    font-weight: 700;
    font-size: 18px;
    color: #0b0b0d;
}
.menuNavigation ul.listItems li>a {
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    color: #0b0b0d;
}
.location img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}
.location span, .call-now span, .call-now span a {
    font-size: 10px;
}
.d-flex {
    flex-wrap: wrap;
}
.left-cont,.right-cont {
    width: 100%;
    margin-bottom: 15px;
}
.choose-cont {flex-wrap: wrap;}
.choose-cont .card {width: 49%;margin-right: 2%;margin-bottom: 2%;}
.choose-cont .card:nth-child(even) {
    margin-right: 0px;
}
.equal-cont {
    width: 100%;
    padding: 30px;
}
.contact form {
    max-width: 100%;
}
.show-sticky-header .menuNavigation {top: 100px;}
.ourmain-menu {display: flex;flex-wrap: wrap;} 
.ourmain-menu li {
    margin: 0px 20px;
    margin-bottom: 10px;
}
.d-flex-cards .card {
    width: 100%;
    margin-right: 0%;
}
.d-flex-cards .card figure figcaption p {
    height: auto;
    -webkit-line-clamp: 2;
}
.d-flex-cards .card figure .img-wrap {
    width: 150px;
}
.d-flex-cards .card figure figcaption {
    width: calc(100% - 150px);
}

.openMenu
.menuNavigation {display: block;}

.singlepage-content {flex-wrap: wrap;}
.article-img {width: 100%;margin-right: 0px;margin-bottom: 10px;}
.main-description {width: 100%;}
.choose-cont figure {
    padding: 10px;
}
.menu-btns {
    max-width: 100%;
    margin: 0px auto;
}
.bg-menu {
    padding: 60px;
}
.click-menu {max-width: 100%;}
.article-social-share {
    margin-top: 0;
}
.article-social-share .post-share {
    width: 35px;
    height: 35px;
}
.article-social-share a {
    margin-left: 0;
    margin-right: 2px;
}
.feedback-fluid {padding: 0px !important;}
.feedback-form-cont {
    padding: 10px 0px;
}
.feedback-form-cont .flex-cont {border-radius: 20px;padding: 0px;}
}
.toogle-bar { display: inline-flex; align-items: center; }